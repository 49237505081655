import React, { useState } from "react";
import { CloudDownload, CopyAll, ViewWeek, Print, Archive } from '@mui/icons-material';
import { IconButton, Tooltip, Menu, Toolbar as MuiToolbar, TextField } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { MenuItemStyled } from "./styles";
import exportFile from '../../helpers/functions/exportFile';
import i18n from "../../helpers/i18n";

const Toolbar = ({ selectedData, onSearch, onChangeColumns, columns, rows, onToggleArchived, isShowArchived }) => {
    const [searchText, setSearchText] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [columnsMenuOpen, setColumnsMenuOpen] = useState(false);
    const [exportMenuOpen, setExportMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // Funções auxiliares
    const handleChange = (event) => {
        const text = event.target.value;
        setSearchText(text);
        onSearch(text);
    };

    const toggleSearch = () => {
        setIsSearchActive(!isSearchActive);
        if (isSearchActive) {
            onSearch('');
        }
    };

    const handleMenuExportOpen = (event) => {
        setExportMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuColumnsOpen = (event) => {
        setColumnsMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuExportClose = () => {
        setExportMenuOpen(false);
        setAnchorEl(null);
    };

    const handleMenuColumnsClose = () => {
        setColumnsMenuOpen(false);
        setAnchorEl(null);
    };

    const handleExport = (type, selectedColumns) => {
        const columnsTable = selectedColumns.map(column => ({ name: column.field, label: column.headerName }));

        if (selectedData.length === 0) selectedData = rows;

        const dataToExport = selectedData.map(row => {
            const rowData = {};
            selectedColumns.forEach(column => {
                rowData[column.field] = row[column.field];
            });
            return rowData;
        });

        exportFile(columnsTable, dataToExport, type);
        handleMenuExportClose();
    };

    const copyDevicesToClipboard = () => {
        const formattedData = selectedData.map(row =>
            `${row.dispositivo}\t${row.tipoDispositivo}\t${row.status}\t${row.dataAtivacao}\t${row.dataSincronia}\t${row.chave}`
        ).join('\n');
        navigator.clipboard.writeText(formattedData);
    };

    const handleColumnToggle = (columnIndex) => {
        const updatedColumns = [...columns];
        updatedColumns[columnIndex].checked = !updatedColumns[columnIndex].checked;
        onChangeColumns(updatedColumns);
    };

    const handlePrint = () => {
        handlePrintSelectedDevices();
    };

    const getCheckedColumns = () => columns.filter(column => column.checked && column.field !== 'ações');

    const handlePrintSelectedDevices = () => {
        let selectedPrintData = selectedData.length === 0 ? rows : selectedData;
        const printContent = generatePrintContent(selectedPrintData);
        const printWindow = window.open('', '_blank');
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
    };

    const generatePrintContent = (selectedPrintData) => {
        const checkedColumns = getCheckedColumns();
        return `
            <html>
                <head>
                    <title>Imprimir Dispositivos</title>
                    <style>
                        body { font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; padding: 30px; margin: 0; background-color: #f4f7fa; color: #333; }
                        h2 { font-size: 24px; font-weight: 600; color: #2c3e50; margin-bottom: 20px; text-align: center; }
                        table { width: 100%; border-collapse: collapse; margin-bottom: 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }
                        th, td { padding: 12px; text-align: left; font-size: 14px; color: #555; border: 1px solid #ddd; }
                        th { background-color: #2c3e50; color: #fff; font-weight: 600; }
                        tr:nth-child(even) { background-color: #f9f9f9; }
                        tr:hover { background-color: #f1f1f1; }
                        td { word-wrap: break-word; }
                        .footer { text-align: center; font-size: 12px; color: #aaa; margin-top: 20px; }
                    </style>
                </head>
                <body>
                    <h2>Dispositivos Selecionados</h2>
                    <table>
                        <thead><tr>${checkedColumns.map(col => `<th>${col.headerName}</th>`).join('')}</tr></thead>
                        <tbody>${selectedPrintData.map(device => `
                            <tr>${checkedColumns.map(col => `<td>${device[col.field]}</td>`).join('')}</tr>`).join('')}
                        </tbody>
                    </table>
                </body>
            </html>
        `;
    };

    const SearchInterface = () => (
        <>
            <Tooltip title={i18n.t('toolBar.search')}>
                <IconButton sx={{ padding: '12px' }} onClick={toggleSearch} aria-label={i18n.t('toolBar.search')}>
                    <GridSearchIcon />
                </IconButton>
            </Tooltip>
            {isSearchActive && (
                <TextField
                    sx={{
                        '& .MuiInputBase-input::placeholder': { font: 'normal normal 400 14px/24px Open Sans' },
                        '& .MuiInputBase-input': { font: 'normal normal 400 14px/24px Open Sans' },
                        '& .MuiInput-underline:before': { borderBottom: 'solid 1px var(--ahg-gray-darken1)' },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'solid 1px var(--ahg-gray-darken1)' },
                        '& .MuiInput-underline:after': { borderBottom: 'solid 1px var(--ahg-gray-darken1)' }
                    }}
                    value={searchText}
                    onChange={handleChange}
                    placeholder={i18n.t('toolBar.search')}
                    variant="standard"
                    size="small"
                    margin="none"
                />
            )}
        </>
    );

    const ExportMenu = () => (
        <Menu anchorEl={anchorEl} open={exportMenuOpen} onClose={handleMenuExportClose}>
            <MenuItemStyled onClick={() => handleExport('csv', getCheckedColumns())}>{i18n.t('toolBar.export.csv')}</MenuItemStyled>
            <MenuItemStyled onClick={() => handleExport('xlsx', getCheckedColumns())}>{i18n.t('toolBar.export.xlsx')}</MenuItemStyled>
            <MenuItemStyled onClick={() => handleExport('pdf', getCheckedColumns())}>{i18n.t('toolBar.export.pdf')}</MenuItemStyled>
        </Menu>
    );

    const ColumnsMenu = () => (
        <Menu anchorEl={anchorEl} open={columnsMenuOpen} onClose={handleMenuColumnsClose}>
            {columns.map((column, index) => (
                <MenuItemStyled key={index}>
                    <label>
                        <input
                            aria-label={`${column.headerName}Checkbox`}
                            type="checkbox"
                            checked={column.checked}
                            onChange={() => handleColumnToggle(index)}
                        /> {column.headerName}
                    </label>
                </MenuItemStyled>
            ))}
        </Menu>
    );

    return (
        <MuiToolbar sx={{
            borderBottomStyle: 'none',
            backgroundColor: '#fff',
            paddingTop: '8px',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            textAlign: 'right',
            alignSelf: 'stretch',
            justifyContent: 'flex-end',
            zIndex: 2,
        }}>
            {SearchInterface()}
            <Tooltip title={i18n.t('toolBar.copyLines')}>
                <IconButton sx={{ padding: '12px' }} onClick={copyDevicesToClipboard}>
                    <CopyAll />
                </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('toolBar.showColumns')}>
                <IconButton sx={{ padding: '12px' }} onClick={handleMenuColumnsOpen}>
                    <ViewWeek />
                </IconButton>
            </Tooltip>
            {ColumnsMenu()}
            <Tooltip title={i18n.t('toolBar.print')}>
                <IconButton sx={{ padding: '12px' }} onClick={handlePrint}>
                    <Print />
                </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('toolBar.export.export')}>
                <IconButton sx={{ padding: '12px' }} onClick={handleMenuExportOpen}>
                    <CloudDownload />
                </IconButton>
            </Tooltip>
            {ExportMenu()}
            <Tooltip title={i18n.t('toolBar.showDeletedDevice')}>
                <IconButton 
                    sx={{ padding: '12px', backgroundColor: isShowArchived ? 'var(--ahg-waterloo-lighten4)' : 'inherit' }} 
                    onClick={onToggleArchived}>
                    <Archive />
                </IconButton>
            </Tooltip>
        </MuiToolbar>
    );
};

export default Toolbar;
